.consentViewerContainer {
  width: 100%;
  overflow: auto;
}

.consentViewerContainer td,
.consentViewerContainer th  {
  border-width: 1px;
  border-color: #99a;
  border-style: solid;
}

.consentViewerContainer table {
  margin: 4px 2px;
  border-collapse: collapse;

}

.consentViewerContainer td,
.consentViewerContainer th {
  vertical-align: top;
  margin: 0px;
  padding: 2px 6px
}

.consentViewerContainer td {
  text-align: left;
  background-color: white;
}

.consentViewerContainer th {
  text-align: right;
  background-color: #eef;
}

.consentViewerContainer .boolean,
.consentViewerContainer .null {
  font-style: italic;
}

.consentViewerContainer .over {
  background-color: pink;
}

